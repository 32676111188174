'use strict';
import React, { useState } from 'react';
import { Row, Col, Container, Button, Spinner } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useHistory, Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Parallax from 'react-rellax'
import axios from 'axios'
import { Url } from '../../GLOBAL/global';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var sessionstorage = require('sessionstorage');

export default function Index(props) {
  let history = useHistory();
  console.log("props", props.name);

  const { register, handleSubmit, formState, getValues } = useForm({ shouldUseNativeValidation: true });
  const [spinner, setSpinner] = React.useState(false);

  function onSubmit(data) {
    setSpinner(true);
    let formdata = new FormData();
    formdata.append('name', data.name);
    formdata.append('email', data.email);
    formdata.append('password', data.pass2);
    formdata.append('phone', data.phone);
    formdata.append('ministry', data.ministry);
    formdata.append('address', data.add);

    const headers = {
      'Content-Type': 'multipart/form-data'
    }

    axios({
      method: 'post',
      url: Url + 'register',
      data: formdata,
      headers: headers
    })
      .then(function (response) {
        console.log(response.data);
        setSpinner(false);
        if (response.data.message === "Registered Successfully...") {
          sessionstorage.setItem("token", response.data.token);
          sessionstorage.setItem("customerId", response.data.id);
          toast.success("Registration Success! Verify Email Id!", { autoClose: 3000 });

          setTimeout(() => history.push({ pathname: '/login', serviceType: props.name }), 3000)
        }
        if (response.data === "email already exists") {
          toast("Email Already Exists!", { autoClose: 3000 });
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  return (
    <div>
      <Container>
        <Row>
          <Col xl={4} sm={12} md={12} xxl={5} className='py-5 my-5'>
            <Parallax speed={-3}>
              <img src={require('../../assets/images/register.png')} height={500} width={500} alt="hands" />
            </Parallax>
          </Col>
          <Col></Col>
          <Col xl={4} sm={12} md={12} xxl={5} className='py-5 my-5'>
            <Parallax speed={-3}>
              <h6 className='heading'>Register</h6>
              <p className='para-content'>Come To Fold And Make Jesus Viral</p>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col sm={12} md={12} xl={6} xxl={6}>
                    <input
                      placeholder="Name"
                      title="Only alphabetic characters are allowed"
                      pattern="[A-Za-z]+"
                      type="text"
                      {...register("name", {
                        required: true,
                        pattern: {
                          value: /^[A-Za-z]+$/,
                          message: 'Only alphabetic characters are allowed',
                        }
                      })}
                      className={`textbox ${formState.errors.name ? "is-invalid" : ""}`}
                    />
                    {formState.errors.name && (
                      <p className="error">Name is required and should contain only alphabetic characters.</p>
                    )}
                  </Col>
                  <Col sm={12} md={12} xl={6} xxl={6}>
                    <input
                      placeholder="Ministry"
                      type="text"
                      {...register('ministry', { required: true })}
                      className={`textbox ${formState.errors.ministry ? "is-invalid" : ""}`}
                    />
                    {formState.errors.ministry && (
                      <p className="error">Ministry is required.</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} xl={6} xxl={6}>
                    <input
                      placeholder="Email"
                      type="email"
                      {...register("email", { required: true })}
                      className={`textbox ${formState.errors.email ? "is-invalid" : ""}`}
                    />
                    {formState.errors.email && (
                      <p className="error">Email is required.</p>
                    )}
                  </Col>
                  <Col sm={12} md={12} xl={6} xxl={6}>
                    <input
                      placeholder="Phone"
                      type="number"
                      {...register("phone", { required: true })}
                      className={`textbox ${formState.errors.phone ? "is-invalid" : ""}`}
                    />
                    {formState.errors.phone && (
                      <p className="error">Phone is required.</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} xl={12} xxl={12}>
                    <textarea
                      placeholder="Full Address"
                      {...register("add", { required: true })}
                      className={`textbox textArea ${formState.errors.add ? "is-invalid" : ""}`}
                      rows={3}
                    />
                    {formState.errors.add && (
                      <p className="error">Full Address is required.</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12} xl={6} xxl={6}>
                    <input
                      placeholder="Password"
                      name="pass1"
                      type="password"
                      {...register("pass1", { required: true })}
                      className={`textbox ${formState.errors.pass1 ? "is-invalid" : ""}`}
                    />
                    {formState.errors.pass1 && (
                      <p className="error">Password is required.</p>
                    )}
                  </Col>
                  <Col sm={12} md={12} xl={6} xxl={6}>
                    <input
                      placeholder="Re-Password"
                      name="pass2"
                      type="password"
                      {...register("pass2", {
                        required: true,
                        validate: (value) =>
                          value === getValues("pass1") || "Passwords do not match."
                      })}
                      className={`textbox ${formState.errors.pass2 ? "is-invalid" : ""}`}
                    />
                    {formState.errors.pass2 && (
                      <p className="error">{formState.errors.pass2.message}</p>
                    )}
                  </Col>
                </Row>
                <Row className='extraRowSpace'>
                  <div class="pkg-button button-text ">
                  <button class="button px-5"  type="submit"  disabled={spinner}>
    {spinner ? (
      <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <span className="ml-2">Registering...</span>
      </>
    ) : (
      "Register"
    )}
  </button>
                  </div>
                </Row>
                <Row align="center" >
                  <Col>Already Have An Account? &nbsp;
                    <Link to='/login' style={{ color: 'black' }}>Sign in</Link>
                  </Col>
                </Row>
              </Form>
            </Parallax>
          </Col>
        </Row>
        <ToastContainer position="top-center" style={{ marginTop: '50vh' }} />
      </Container>
    </div>
  );
}
