import React from 'react'
import { Row } from 'react-bootstrap'
import Platform from 'react-platform-js'
import { FaArrowDown } from "react-icons/fa"
// import '../../style/about.scss'
import { FaArrowLeft } from "react-icons/fa";


export default function pillars() {
    
    return (
        <>
  



<section>
        {/* <!-- start main div --> */}
        {/* <div class="piller-main-div">
            <div class="piller-top-div">
                <div class="div-one">
                    <img src={require('../../assets/images/images.png')} alt="" id="p-icon" />
                    <p>to be a tool for churches and ministries <br></br> to accomplish greate commission given to us</p>
                </div>
                <div class="div-two">
                   
                    <FaArrowLeft size={60} id="h1-icon" />
                    <h1>Pillars</h1>
                </div>
            </div>

            <div class="piller-row-one">
                <div class="row-one-img">
                    <img  src={require('../../assets/images/Rectangle 37.png')} alt="" />
                    <h1>Our Vision</h1>
                </div>
            </div>

            <div class="piller-row-two">
                <div class="row-two-img">
                    <img src={require('../../assets/images/Rectangle 38.png')} alt="" />
                </div>
              <div class="our-mission-div">
                    <h1>Our Mission</h1>
                    <div class="div-one">
                        <img src={require('../../assets/images/images.png')} alt="" id="p-icon" />
                        <p>to be a tool for churches and ministries <br></br> to accomplish greate commission given to us</p>
                    </div>
                </div>
            </div>
            <div class="piller-row-three">
                <div class="faith-statement">
                    <h1>Faith Statement</h1>
                    <div class="div-one">
                        <p>to be a tool for churches and ministries <br></br> to accomplish greate commission given to us</p>
                        <img src={require('../../assets/images/images.png')} alt="" id="p-icon" />
                    </div>
                </div>
                <div class="row-three-img">
                    <img src={require('../../assets/images/Rectangle 39.png')} alt="" />
                </div>
            </div>

            <div class="piller-Colum-four">
                <div class="row-four-img">
                    <img  src={require('../../assets/images/Rectangle 40.png')} alt="" />
                    <h1>Core Values</h1>
                </div>
                <div class="div-one">
                    <p>to be a tool for churches and ministries <br></br> to accomplish greate commission given to us</p>
                    <img src={require('../../assets/images/images.png')} alt="" id="p-icon" />
                </div>
              </div>
            </div> */}
        {/* <!-- end main div --> */}

        {/* Pillar Section */}

<div class="section popup" id="pillars">
  <div>
	<h1 style={{color:'#f5c812'}}><i class="fa fa-arrow-left" aria-hidden="true"></i>Four<br></br>Pillars</h1>
    <ul>
    <li>
    <img src={require('../../pages/About/Images_for_pillars_two/OurMission.png')}  alt="Core Values of Connect Media Networks"/>

        <img src={require('../../../src/assets/imgs/pillar1.jpg')} alt="Connect Media Networks Vision"/>
    </li>
    <li>
    	{/* <h2>Our Mission</h2> */}
    <img src={require('../../pages/About/Images_for_pillars_two/OurVision.png')}  alt="Core Values of Connect Media Networks"/>

        <img src={require('../../../src/assets/imgs/pillar2.jpg')} alt="Connect Media Networks Mission"/>
    </li>
    <li>
    	{/* <h2>Faith Statement</h2> */}
    <img src={require('../../pages/About/Images_for_pillars_two/Faith Statement.png')}  alt="Core Values of Connect Media Networks"/>

        <img src={require('../../../src/assets/imgs/pillar3.jpg')} alt="Faith Statement of Connect Media Networks"/>
    </li>
    <li>
    	{/* <h2>Core Values</h2> */}
    <img src={require('../../pages/About/Images_for_pillars_two/gitignore.png')}  alt="Core Values of Connect Media Networks"/>

        <img src={require('../../../src/assets/imgs/pillar4.jpg')}  alt="Core Values of Connect Media Networks"/>
    </li>
    </ul>
    <div id="pillar2" class="subtitle">
    <div class="content">
    <h2>Our Vision</h2>
    To be a pillar of support for Churches and servants of God in spreading the word by leveraging digital space to its utmost utility. We strongly believe that the teachings of the Lord Jesus should not be limited to the Sunday service and connect groups, but also be brought to the forefront of everyone's lives through technology, especially connected TV and social media engagement. We are fully committed to creating a strong and engaging presence in connected TV and social media that trumps all other content that keeps people distracted from the gospel.

    </div>
    <i class="fa fa-times-circle" aria-hidden="true"></i>
    </div>
    <div id="pillar1" class="subtitle">
    <div class="content">
    <h2>Our Mission</h2>
    To be a tool for Churches and Ministries to accomplish the great commission given to us. Mathew 28:19-20
    </div>
    <i class="fa fa-times-circle" aria-hidden="true"></i>
    </div>
    <div id="pillar3" class="subtitle">
    <div class="content">
    <h2>Faith Statement</h2>
    We believe in the Trinity - Father, Son and the Holy Spirit.<br></br>
    We believe that Jesus came to this world, bore our sin, died for us, and on the third day rose back to life by the power of the Holy Spirit to rescue us from sin, curse, and death so that whoever believes in Him will not perish but have everlasting life.<br></br>
We believe that the Lord Jesus is coming back again to take His chosen to His eternal kingdom where there is no suffering but eternal joy!<br></br>
It is our joy and greatest commission to spread this good news of Jesus to the world.

	</div>
    <i class="fa fa-times-circle" aria-hidden="true"></i>
    </div>
    <div id="pillar4" class="subtitle scrollbar">
    	<div class="content">
        <h2>Core Values</h2>
        "Jesus said to him, 'If you can believe? All things are possible for one who believes." Mark 9:23<br></br>
Our faith in Jesus is the driving force that propels what we do. This is our most fundamental and the most important aspect which elevates the work we do.<br></br>
<b>Think modern - keep Jesus's mind</b><br></br>
Do not conform to the pattern of this world, but be transformed by the renewing of your mind. Then you will be able to test and approve what God's will is - his good, pleasing and perfect will. Romans 12:2.<br></br>
We live in times where everything from education to business is all happening online. More people have smartphones with them at all times than a Bible. While physical books may become irrelevant in modern times, Christ's teachings will always be relevant. We are always learning and finding new ways to engage people online to spread the word. <br></br>
<b>Collaboration</b><br></br>
Therefore go and make disciples of all nations. Matthew 29:19.<br></br>
We are fundamentally a collaborative service. We strive to capture the identity of the global church through collaboration so that they have their unique voice reflected in social media.<br></br>
Go into the world...<br></br>
We can quicken the coming of the Lord Jesus by spreading the saving knowledge of Jesus to the ends of the earth. We can do this TOGETHER as disciples of the Lord Jesus. <br></br>
<b>To be a light</b><br></br>
Again Jesus spoke to them, saying, "I am the light of the world. Whoever follows me will not walk in darkness, but will have the light of life." John 8:12.<br></br>
Wherever we are and wherever we go, we always carry the light of Jesus with us. No matter what challenges we face, we work with a smile on our faces knowing that the Lord is with us.
</div>
    <i class="fa fa-times-circle" aria-hidden="true"></i>
    </div>
  </div>   
  {/* <script>
   $("#pillars li").click(function(){
	  var i=$(this).index()+1;
	  $("#pillars .subtitle").removeClass("active");
	  $("#pillar"+i).addClass("active");
  });
  $("#pillars .subtitle i").click(function(){
	  $(this).parent().removeClass("active");
  });
  </script> */}
</div>
</section>

        </>
    )
}
