import React from 'react'
import { Container,Row, Col} from 'react-bootstrap'
import { FiPlusCircle } from "react-icons/fi"
import { FiArrowRight } from "react-icons/fi"
import { FiArrowLeft } from "react-icons/fi"
import { FaQuestion } from "react-icons/fa"
// import '../../style/features.scss'
import  Parallax from 'react-rellax';
// import bg from '../../assets/images/Group 148.png'

export default function Whyconnect() {
    return (
        <>
        {/* <section id='whyconnect1'>
           
        <section>
            <div class="why-connect-main-div">
                <h1>Why Connect</h1>
                <p>Do you know how many are actually watching your live-stream videos and diligently following you online? 
                Are you happy with your online reach after investing SO MUCH in to media ministry? 
                Hmm…you are not alone!</p>
                <p>In the recent past, many congregations and houses of worship brought their church services online with little to mediocre knowledge.</p>

                <br></br>
                <p>We all have rapidly adapted ourselves to the era of smartphones. But the full utilization of social media is still not the best for most of the churches. Sending out newsletters and marketing emails are becoming outdated. Billboards & yellow pages can do only so much. But, Social Media has emerged as one of the most effective and necessary ways to reach out and engage your members if
                utilized to it’s potential. </p>                
                <br></br>
                <p>Challenges Facing Churches & Ministries Today:</p>
                <br></br> 
                <li>Time: Pastors and staff members wear multiple hats.</li>
                <li>Expertise: Pastors and staff members don’t have the expertise with internet and social media.</li>
                <li>Money: Hiring someone to do it costs around $30-60K/year.</li>
                <li>Consistency: Having a volunteer do it doesn’t maintain the level of excellence and consistency.</li>
                <h2>learn more</h2>
            </div>
        </section>


        </section> */}

{/* why connect */}

<div class="section" id="why">
	<link rel="stylesheet" href="net-particles.css" />
    <span class="netparticles"></span>
	{/* <script src="net-particles.js"></script> */}
    {/* <? if(false){ ?>
	<script src="https://cdn.jsdelivr.net/npm/vanta@latest/dist/vanta.net.min.js"></script>
	<script>
    VANTA.NET({
      el: "#why",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
	  color: 0x787845,
	  backgroundColor: 0x262744,
	  points: 7.00,
	  maxDistance: 17.00,
	  spacing: 19.00,
	  speed:4,
    })
    </script>
    <? } ?> */}
  <div class="my-auto">
	  <h1 class="text-center mt-2 mt-lg-5">Why<span class="connect-title">&nbsp;</span> ?</h1>
      <p class="whysubhead text-center mt-lg-4">If you are serious in expanding your ministry, we are double serious to help you grow!</p>
    
       <p class="whypara my-2 ">MyChurchTV will get you connected to a bigger audience where people will start following you from different nations and your location area.<br></br>
       A unique opportunity to grow locally and go globally and get HIGHLIGHTED from where you are.<br></br>
       You will have increased outreach, engagement and community, building opportunities and YOUR ministry placed on international platform.<br></br>
       We are fully dedicated to help Churches, Evangelists and Ministers of God to spread the good news of Jesus to the ends of the world.</p>
       <div class="row mt-2 mt-xl-4 mobimg">
        <div class="col-12 col-md-6 text-center">
          <p class="whyhead1 my-2 text-center">Be on all the platforms</p>
           <a href='https://thankyou.mychurchtv.app/book' target='blank'><img src={require('../../assets/images/whyimg1.png')} class="img-fluid w-50"></img></a> 
        </div>
        <div class="col-12 col-md-6 text-center">
          <p class="whyhead1 my-2 text-center">Be on your favorite devices</p>
          <img src={require('../../assets/images/whyimg2.png')} class="img-fluid w-60"></img>
        </div>
       </div>
       {/* <div class="content">
        <div class="explanation">
       
          <div id="stat-graph">
                <div>
                <b  style={{ fontFamily: 'Poppins',fontWeight:'200'}} >Churches who used Digital Marketing and Social Media Management effectively saw a great increase in online viewership and saw new members coming to their church services in-person!</b>
                <div className='why-connect-img-cont' >
                  <img src={require('../../assets/images/image geare.png')} style={{paddingRight:'20px'}} height={"auto"} width={"30%"} alt="hands"></img>
                <h3 style={{ fontFamily: 'Kanit'}} ><span style={{color:'white',fontSize:'18px', fontFamily: 'Poppins'}}> We know how to get you there. </span> <br></br>Your 100% satisfaction is guaranteed.</h3>

                </div>

                </div>
                <div style={{width:'100%'}}>
                    <div id="graph-container">
                    <div id="graph-wrap">
                      <svg id="graph" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="1795" height="742" viewBox="0 0 1795 742" preserveAspectRatio="xMinYMin meet">
                      <defs>
                        <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                          <stop offset="0" stop-color="#1f182d"/>
                          <stop offset="0.582" stop-color="#0060a0"/>
                          <stop offset="1" stop-color="#4da3ce"/>
                        </linearGradient>
                        <filter id="Path_1" x="55.636" y="195.721" width="1721.403" height="504.536" filterUnits="userSpaceOnUse">
                          <feOffset dx="18" dy="16" input="SourceAlpha"/>
                          <feGaussianBlur stdDeviation="13" result="blur"/>
                          <feFlood flood-color="red"/>
                          <feComposite operator="in" in2="blur"/>
                          <feComposite in="SourceGraphic"/>
                        </filter>
                      </defs>
                      <g id="Group_2" data-name="Group 2" transform="translate(-39 -1181)">
                        <g id="Rectangle_1" data-name="Rectangle 1" transform="translate(39 1181)" stroke="#707070" stroke-width="1" fill="url(#linear-gradient)">
                          <rect width="1795" height="742" rx="15" stroke="none"/>
                        </g>
                        <g id="Group_1" data-name="Group 1" transform="translate(-27 968)">
                          <line id="Line_1" data-name="Line 1" x2="1686" transform="translate(120.5 749.5)" fill="none" stroke="#fff" stroke-width="6"/>
                          <line id="Line_2" data-name="Line 2" x2="1686" transform="translate(120.5 588.5)" fill="none" stroke="#fff" stroke-width="6"/>
                          <line id="Line_3" data-name="Line 3" x2="1686" transform="translate(120.5 434.5)" fill="none" stroke="#fff" stroke-width="6"/>
                          <line id="Line_4" data-name="Line 4" x2="1686" transform="translate(120.5 273.5)" fill="none" stroke="#fff" stroke-width="6"/>
                          <line id="Line_5" data-name="Line 5" x2="1686" transform="translate(120.5 874.363)" fill="none" stroke="#fff" stroke-width="6"/>
                          <text id="_10" data-name="10" transform="translate(593 929)" fill="#fff" font-size="42" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">10</tspan></text>
                          <text id="_20" data-name="20" transform="translate(1171 929)" fill="#fff" font-size="42" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">20</tspan></text>
                          <text id="_30" data-name="30" transform="translate(1749 929)" fill="#fff" font-size="42" font-family="SegoeUI, Segoe UI"><tspan x="0" y="0">30</tspan></text>

                        </g>
                      </g>
                      <g id="Group_4" data-name="Group 4" transform="translate(-50 -220)">
        <g id="Group_3" data-name="Group 3">
          <path id="Path_4" data-name="Path 4" d="M134.652,856.757l232.836.07s22.427-.329,27.592-4.07,9.62-11.5,12.449-15.992c14.991-23.8,20.735-132.136,62.822-142.975,56.193-15.681,116.491-20.995,145.523-32.617s101.751-87.807,168.1-95.343,250.177-13.636,283.52-15.054,169.129-87.026,214.728-87.426,160.257,1.348,200.722,0S1757.48,302.383,1757.48,302.383" fill="none" stroke="#F02000" stroke-width="12"/>
          <path id="Path_4" data-name="Path 4" d="M134.652,856.757l232.836.07s22.427-.329,27.592-4.07,9.62-11.5,12.449-15.992c14.991-23.8,20.735-132.136,62.822-142.975,56.193-15.681,116.491-20.995,145.523-32.617s101.751-87.807,168.1-95.343,250.177-13.636,283.52-15.054,169.129-87.026,214.728-87.426,160.257,1.348,200.722,0S1757.48,302.383,1757.48,302.383" fill="none" stroke="yellow" stroke-width="1"/>
        </g>
      </g>
                    </svg>
                    <div id="graph-pointer"></div>
                    </div>
                    </div> */}
                    {/* <script>
                    function graphRepaint(){
                        var availableWidth=$("#graph-container").width();
                        var availableHeight=$("#graph-container").innerHeight();
                        var contentWidth=$("#graph-container svg").width();
                        var contentHeight=$("#graph-container svg").innerHeight();
                        var scale = Math.min( 
                          availableWidth / contentWidth, 
                          availableHeight / contentHeight 
                        );
                        $("#graph-wrap").css("transform","scale("+scale+","+scale+")").fadeIn(1000);
                    }
                    $(window).on('load',function () {
                        if($("#why .stats").css("display")=="none"){
                            $("#why .stats").remove();
                            return;
                        }
                        graphRepaint();				
                    });
                    $(window).resize(graphRepaint);
                    </script> */}
                {/* </div>
            </div>



          





        </div>
	  </div> */}
  </div>
</div>

        </>
    )
}
