import React from 'react';
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";
import Textbox from '../../components/TextBox';
import Form from 'react-bootstrap/Form';
import TextArea from '../../components/TextArea';
import Buttons from '../../components/Packages/Buttons';
import { useForm } from 'react-hook-form';
import { Url } from '../../GLOBAL/global';
import { useHistory, Link } from "react-router-dom";
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FooterPage from '../../components/Footer/FooterPage';
var sessionstorage = require('sessionstorage');

export default function Contact() {
    const { register, handleSubmit, formState: { errors } } = useForm({ shouldUseNativeValidation: true });
    const [spinner, setSpinner] = React.useState(false);
    let history = useHistory();

    const validateName = (name) => {
        // Regular expression to allow only alphabets and spaces
        const regex = /^[A-Za-z\s]+$/;
        return regex.test(name);
    };

    const onSubmit = (data) => {
        setSpinner(true);

        if (!validateName(data.name)) {
            // Display an error message if the name contains invalid characters
            toast.error("Please enter a valid name with only alphabets and spaces.", { autoClose: 3000 });
            setSpinner(false);
            return;
        }

        let formdata = new FormData();
        formdata.append('name', data.name);
        formdata.append('email', data.email);
        formdata.append('phone', data.phone);
        formdata.append('subject', data.subject);
        formdata.append('message', data.message);

        const headers = {
            'Content-Type': 'multipart/form-data',
        }

        axios({
            method: 'post',
            url: Url + 'contactPost',
            data: formdata,
            headers: headers
        })
            .then(function (response) {
                setSpinner(false);
                console.log(response);
                if (response.data === "ok") {
                    toast.success("Message sent successfully !!", { autoClose: 3000 });
                    history.push('/home');
                }
            })
            .catch(function (response) {
                console.log(response);
            });
    };

    return (
        <>
            {/* contact */}
            <div className="section" id="contact">
                <div>
                    <div id="card-wrap">
                        <div id="contact-card">
                            <ul>
                                <li><img id="card-logo" src={require('../../../src/assets/imgs/connect-media-logo.png')} alt="connect-media-logo" /></li>
                                <li><a href="mailto:connect@connectmedianetworks.com" target="_blank" rel="noreferrer">
                                    <div><i className="fa fa-envelope-o" aria-hidden="true"></i></div>
                                    <div>connect@connectmedianetworks.com</div></a>
                                </li>
                                <li><a href="https://www.facebook.com/connectmedianetworks" target="_blank" rel="noreferrer">
                                    <div><i className="fa fa-facebook" aria-hidden="true"></i></div>
                                    <div>@Connectmedianetworks</div></a>
                                </li>
                                <li><a href="https://instagram.com/connectmedianetworks" target="_blank" rel="noreferrer">
                                    <div><i className="fa fa-instagram" aria-hidden="true"></i></div>
                                    <div>ConnectMediaNetworks</div></a>
                                </li>
                                <li><a href="https://www.youtube.com/channel/UCwDPtQyao-AHKRhioG3YMXA" target="_blank" rel="noreferrer">
                                    <div><i className="fa fa-youtube-play" aria-hidden="true"></i></div>
                                    <div>Connect Media Networks</div></a>
                                </li>
                                <li><a href="https://www.linkedin.com/company/connectmedianetworks" target="_blank" rel="noreferrer">
                                    <div><i className="fa fa-linkedin" aria-hidden="true"></i></div>
                                    <div>ConnectMediaNetworks</div></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="contact-form">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col sm={12} md={12} xl={6} xxl={6}>
                                    {/* Name input */}
                                    <input
                                        placeholder="Name"
                                        type="text"
                                        {...register("name", {
                                            required: true,
                                            pattern: /^[A-Za-z\s]+$/ // Only allow alphabets and spaces
                                        })}
                                        className={`textbox ${errors.name ? 'is-invalid' : ''}`}
                                    />
                                    {errors.name && <div className="invalid-feedback">Please enter a valid name with only alphabets and spaces.</div>}
                                </Col>
                                <Col sm={12} md={12} xl={6} xxl={6}>
                                    <input placeholder="Email" type="email" {...register("email", { required: true })} className={`textbox ${errors.email ? 'is-invalid' : ''}`} />
                                    {errors.email && <div className="invalid-feedback">Please enter a valid email address.</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} xl={6} xxl={6}>
                                    <input placeholder="Contact No" type="number" {...register("phone", { required: true })} className={`textbox ${errors.phone ? 'is-invalid' : ''}`} />
                                    {errors.phone && <div className="invalid-feedback">Please enter a valid phone number.</div>}
                                </Col>
                                <Col sm={12} md={12} xl={6} xxl={6}>
                                    <input placeholder="Subject" type="text" className={`textbox ${errors.subject ? 'is-invalid' : ''}`} {...register("subject", { required: true })} />
                                    {errors.subject && <div className="invalid-feedback">This field is required.</div>}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12} xl={12} xxl={12}>
                                    <textarea placeholder="Message"
                                        {...register("message", { required: true })}
                                        rows={3}
                                        className={`textbox textarea ${errors.message ? 'is-invalid' : ''}`}
                                    ></textarea>
                                    {errors.message && <div className="invalid-feedback">This field is required.</div>}
                                </Col>
                            </Row>
                            {(!spinner === false) ? <Buttons text="Submit Request" type="submit" disabled={true} /> : <Buttons text="Submit Request" type="submit" />}
                            {spinner &&
                                <Spinner
                                    style={{ marginTop: '1.7rem', marginLeft: '-2.7rem' }}
                                    animation="border"
                                    role="status"
                                />
                            }
                        </Form>
                    </div>
                </div>
            </div>
            <ToastContainer style={{ marginTop: '50vh' }} position="top-center" />
            <button className="chat-button" style={{ display: 'none' }}><i className="fa fa-comments" aria-hidden="true"></i></button>
        </>
    );
}
